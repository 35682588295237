.main-card {
  min-width: 500px;
  min-height: 400px;
  border-radius: 10px !important;
  padding: 3rem 5rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  max-width: 100%;
}

.root-div {
  background-color: #000;
  overflow: auto;
  padding: 17px;
  min-height: 100vh;
  /* background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.8911939775910365) 43%,
    rgba(251, 255, 71, 0.7791491596638656) 100%
  ); */
}

@media (max-width: 768px) {
  .root-div {
    padding: 0 !important;
  }

  .language-dropdown-root {
    z-index: 9;
    top: 16px;
  }
  .main-card {
    height: 100%;
    border-radius: 0 !important;
    box-shadow: none;
    padding: 20px;
  }
  .root-div > .row {
    width: 100%;
    margin: 0;
  }

  .root-div > .row > div[class^='col-'] {
    height: 100%;
    padding: 0;
  }

  .text-md {
    font-size: 30px;
  }
  .text-md-sm {
    font-size: 25px;
  }
}

@media (max-width: 576px) {
  .page-title {
    flex: 0;
    margin-left: 15px;
  }
}

.language-dropdown > button,
.language-dropdown > button:focus,
.language-dropdown > button:active {
  background: white !important;
  color: black !important;
  border-color: black !important;
}

.language-dropdown .dropdown-item.active,
.dropdown-item:active {
  background: black !important;
}
